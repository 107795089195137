<template>
  <div class="wrapper">
    <div class="navigation">
      <!-- <h1 class="logo">
        <span v-if="MenuLogoImage==='' || MenuLogoImage===undefined || MenuLogoImage===null ">
        <img src="@/assets/img/logo.png" alt="solum esl dashboard" />
        </span>
        <span v-else>
          <img :src="MenuLogoImage" alt="solum esl dashboard" style="width:183px;height:50px" />
        </span>
      </h1>
      <button type="button" class="btn-nav-fold">
        <img src="@/assets/img/expandMenu-small.png" alt="열기" />
      </button> -->
      <!-- 좌측 메뉴 -->
      <Navigator />
    </div>
    <!-- 우측 콘텐츠 -->
    <div class="contentsWarp">
      <!-- 콘텐츠 헤더: 공통 -->
      <header class="header">
        <!-- Store 검색창 -->
        <div ref="searchPublicBar" class="search">
          <v-form class="d-flex align-center">
            <!-- Store 선택팝업 -->
            <v-btn
              :disabled="searchPublicBarDisabled"
              class="btn-search"
              @click="openSearchStore()"
              text
            >
              <img
                v-show="true"
                ref="searchIconON"
                src="@/assets/img/ico-top-search.png"
                alt=""
              />
              <img
                v-show="false"
                ref="searchIconOFF"
                src="@/assets/img/ico-top-search-disabled.png"
                alt=""
              />
            </v-btn>
            <popupStore
              :search="searchStore"
              :dialog_store="dialog_store"
              @closeMainStoreModal="closeMainStoreModal"
            ></popupStore>
            <v-autocomplete
              v-model="searchStore"
              :items="computedStoreItems"
              :filter="searchStoreFilter"
              :loading="isLoading"
              item-text="description"
              item-value="stores"
              :search-input.sync="searchStoreLording"
              :placeholder="`${$t('Select the store')}`"
              :disabled="searchPublicBarDisabled"
              color="red"
              return-object
            >
            </v-autocomplete>
          </v-form>
        </div>
        <div class="d-flex align-center">
          <p key="selectedStore" class="h_storeInfo">{{ selectedStoreText }}</p>
          <!-- 언어선택 -->
          <div class="h_lang drop-down">
            <button type="button" @click="toggleDropdown" class="btn-dropdown" name="language-dropdown">
              {{ currentLanguage }}
            </button>

            <ul  class="drop-box custom-scrollBox" style="height:200px;overflow-y:hide">
              <li
                v-for="(language, index) in languagelist"
                style="cursor: pointer;padding:10px"
                :key="language.id"
                @click="setActive(language, index)"
              >
                {{ language.languageName }}
              </li>
            </ul>
            <!-- <ul class="drop-box">
              <li>
                <button type="button" @click="changLanguage('en')">
                  English
                </button>
              </li>
              <li>
                <button type="button" @click="changLanguage('ko')">
                  한국어
                </button>
              </li>
              <li>
                <button type="button" @click="changLanguage('de')">
                  Deutsche
                </button>
              </li>
              <li>
                <button type="button" @click="changLanguage('fr')">
                  Francais
                </button>
              </li>
              <li>
                <button type="button" @click="changLanguage('zh')">
                  中文
                </button>
              </li>
              <li>
                <button type="button" @click="changLanguage('es')">
                  Espanol
                </button>
              </li>
              <li>
                <button type="button" @click="changLanguage('it')">
                  Italiano
                </button>
              </li>
              <li>
                <button type="button" @click="changLanguage('por')">
                  Portugal
                </button>
              </li>
              <li>
                <button type="button" @click="changLanguage('jp')">
                  日本語
                </button>
              </li>
            </ul> -->
          </div>
          <!-- 사용자 정보 -->
          <div class="h_member drop-down">
            <button type="button" @click="toggleDropdown" class="btn-dropdown">
              {{ displayUserId }} <i>{{ user.accountGroup }}</i>
            </button>
            <div class="drop-box">
              <div class="_member">
                <p class="name">{{ user.firstName }}</p>
                <p class="id">(ID : {{ displayUserId }})</p>
              </div>
              <div class="myAccount">
                <router-link :to="{ path: MyAccountLink }">{{
                  $t('My Account')
                }}</router-link>
              </div>
              <div v-if="cloudMode" class="dashboard_ver">
                <p>SoftWare</p>
                <div class="versionInfo">
                  <p>Release Version : {{ user.releaseVersion }}</p>
                  <p>Dashboard : {{ DashBoard_Release }}</p>
                  <p>Core : {{ user.coreRevision }}</p>
                  <p>Portal : {{ user.portalRevision }}</p>
                </div>
              </div>
              <main-software-information v-else />
              <div>
                <v-btn class="btn-logout" @click="onLogout" text width="100%">{{
                  $t('Log out')
                }}</v-btn>
              </div>
              <main-privacy-policy />
            </div>
          </div>
        </div>
      </header>
      <!-- 콘텐츠 -->
      <div class="contents">
        <router-view></router-view>
      </div>
    </div>
    <!--Message -->
    <div>
      <v-dialog v-model="messageDialog" width="300">
        <v-card class="popup add_store_popup" align="center" justify="center">
          <v-row>
            <v-col>
              <span v-text="messageText"></span>
            </v-col>
          </v-row>
          <v-card-actions class="d-flex justify-center">
            <v-btn text icon @click="messageDialog = false" class="btn"
            >{{$t('OK')}}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!-- Loading -->
    <div>
      <v-dialog v-model="loadingDialog" width="300" persistent>
        <v-card class="popup add_store_popup" align="center" justify="center">
          <v-row>
            <v-col>
              <span v-text="loadingText"></span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-progress-linear value="15" indeterminate></v-progress-linear>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
    </div>
    <!-- 로딩바 레이어 -->
    <div :class="{ loadingLayer: loadingApi, loadingLayerOff: !loadingApi }">
      <div class="loadingBar">
        <v-progress-circular
          indeterminate
          :size="50"
          :width="7"
          color="#ddd"
        ></v-progress-circular>
      </div>
    </div>
  </div>
</template>

<script>
// utils
import EventBus from '@/plugins/eventBus.js'
import commons from '@/plugins/commons'
import configs from '@/plugins/configs'
import codes from '@/plugins/codes'
// import loadLocaleMessages from '../i18n.js'

// components
import popupStore from './MainPopupStore'
import Navigator from '@/components/Navigator'
import { keepUserSignedIn, clearIntervals } from '@/plugins/healthCheck'
import MainSoftwareInformation from '@/views/MainSoftwareInformation'
import MainPrivacyPolicy from '@/views/MainPrivacyPolicy'
// cloud
import { cloudLogout } from '@/adLogin/authPopup'
// etc
import '@/assets/css/main.css'
import axios from 'axios'
const CUSTOMER = configs.Customer

export default {
  name: 'Main',
  components: {
    Navigator,
    popupStore,
    MainSoftwareInformation,
    MainPrivacyPolicy
  },
  data () {
    return {
      productionType: process.env.VUE_APP_PRODUCTION_TYPE,
      requests: {
        getUser: {
          method: 'get',
          url: '/api/common/account/detail'
        },
        logoutUser: {
          method: 'get',
          url: '/api/common/logout'
        },
        gerreservedData: {
          method: 'get',
          url: '/api/common/articles/map/reserved'
        },
        // toenableLCDtab: {
        //   method: 'get',
        //   url: '/api/common/lcd/check'
        // },
        getStoreSummary: {
          method: 'get',
          url: '/api/common/store/summary'
        }
      },
      searchPublicBarDisabled: false,
      currentLanguage: this.$store.getters['dataStore/GET_CURRENT_LANGUAGE'],
      displayUserId: '',
      drawer: null,
      dialog_store: false,
      messageDialog: false,
      messageText: '',
      loadingApi: false,
      loadingDialog: false,
      loadingText: '',
      searchStore: {},
      searchStoreLording: null,
      isLoading: false,
      user: {},
      DashBoard_Release: '',
      languagelist: [],
      count: 0,
      MyAccountLink: '/User/MyAccount',
      MenuLogoImage: {},
      MSPStatus: false
    }
  },
  computed: {
    cloudMode () {
      return this.productionType === 'cloud'
    },
    selectedStore () {
      return this.$store.state.dataStore.selectedStore
    },
    searchStoreItems () {
      return this.$store.state.dataStore.managedStores
    },
    computedStoreItems () {
      return this.searchStoreItems.map(item => {
        let description = ''
        if (item.company !== null) {
          description += item.company
          if (item.country !== null) {
            description += ' > ' + item.country
            if (item.region !== null) {
              description += ' > ' + item.region
              if (item.city !== null) {
                description += ' > ' + item.city
                if (item.code !== null) {
                  description += ' > ' + item.name + '(' + item.code + ') '
                }
              }
            }
          }
        }
        return Object.assign({}, item, { description })
      })
    },
    selectedStoreText () {
      const selectedStore = this.selectedStore
      if (commons.notEmpty(selectedStore)) {
        const company = commons.isValue(selectedStore.company)
        const country = commons.isValue(selectedStore.country)
        const region = commons.isValue(selectedStore.region)
        const city = commons.isValue(selectedStore.city)
        const code = commons.isValue(selectedStore.code)
        const name = commons.isValue(selectedStore.name)
        return (
          company +
          ' ' +
          country +
          ' ' +
          region +
          ' ' +
          city +
          ' (' +
          name +
          ':' +
          code +
          ') '
        )
      } else {
        return ''
      }
    }
  },
  watch: {
    selectedStore () {
      this.getTopSearchRefresh()
    },
    searchStore (store) {
      this.$store.dispatch('dataStore/UPDATE_SELECTED_STORE', store)
      this.TogetTimezone(store)
    },
    searchPublicBarDisabled (disabled) {
      this.$refs.searchIconON.style.display = disabled ? 'none' : 'block'
      this.$refs.searchIconOFF.style.display = disabled ? 'block' : 'none'
    },
    searchStoreLording (val) {}
  },
  created () {
    EventBus.$on('setSearchStore', store => {
      this.searchStoreItems = [store, ...this.searchStoreItems]
      this.searchStore = this.searchStoreItems.shift()
    })
    // open Select Store Popup
    EventBus.$on('openSelectedStores', () => {
      this.openSearchStore()
    })
    // enable Select Store Popup
    EventBus.$on('enableSelectedStores', state => {
      this.searchPublicBarDisabled = !state
    })
    EventBus.$on('setManagedStores', () => {
      this.setManagedStores()
    })
    // open message Alert
    EventBus.$on('messageAlert', (text, type) => {
      if (!type) {
        this.messageText = text
        this.messageDialog = true
      } else if (type === 'YesNo') {
        this.messageText = text + type
        this.messageDialog = true
      }
    })
    // loading v-progress-circular on
    EventBus.$on('loadingOn', () => {
      if (!this.loadingApi) this.loadingApi = true
    })
    // loading v-progress-circular off
    EventBus.$on('loadingOff', () => {
      if (this.loadingApi) this.loadingApi = false
    })
    EventBus.$on('toggleLoadingModal', text => {
      this.loadingText = this.$t(text)
      this.loadingDialog = !this.loadingDialog
    })
    EventBus.$on('autoLogout', () => {
      this.onLogout()
    })
  },
  methods: {
    getTopSearchRefresh () {
      var getSelectedStore = this.$store.state.dataStore.selectedStore.code
      for (const gt of this.computedStoreItems) {
        if (gt.code === getSelectedStore) {
          this.searchStore = gt
        }
      }
    },
    // To get initial all language list
    togetlanguagelist () {
      const config = { params: {} }
      this.$utils
        .callAxios(
          codes.requests.getLanguageList.method,
          codes.requests.getLanguageList.url,
          config
        )
        .then((res) => {
          if (res.data) {
            console.log(res.data)
            this.languagelist = res.data.languageList
            const userSubscriptionKey = this.languagelist[0].languageId
            const indexOfObject = this.languagelist.findIndex(
              (o) => o.languageId === userSubscriptionKey
            )
            if (localStorage.getItem('languagecontent') === null) {
              this.setActive(this.languagelist[0], indexOfObject)
            }
            if (localStorage.getItem('languagecontent') !== null) {
              console.log(this.$store.getters['dataStore/GET_CURRENT_LANGUAGE'])
              const obj = {
                languageName: this.$store.getters['dataStore/GET_CURRENT_LANGUAGE']
              }
              const datavalue = this.languagelist.filter(function (person) { return person.languageName === obj.languageName })
              this.togetlanguagecontent(datavalue[0].languageId)
            }
          }
        })
        .catch((error) => {
          console.warn(`Could not find any Language List : ${error}`)
        })
    },

    // Active class
    setActive (subscription) {
      console.log(subscription)
      // this.activeIndex = index
      // this.languagelist[index].languageId = subscription.languageId
      this.currentLanguage = subscription.languageName
      this.togetlanguagecontent(subscription.languageId)
    },
    // To get language content
    togetlanguagecontent (id) {
      console.log(this.$store.getters['dataStore/GET_CURRENT_LANGUAGE'])
      // })
      // if (code === undefined) {
      //   code = this.$store.getters['dataStore/GET_CURRENT_LANGUAGE']
      // }
      console.log(id)
      const config = {
        params: {
          languageId: id,
          export: null,
          contents: true
        }
      }
      this.$utils
        .callAxios(
          codes.requests.getLanguageContent.method,
          codes.requests.getLanguageContent.url,
          config
        )
        .then((res) => {
          if (res.data) {
            console.log(res.data)
            console.log(res.config)
            console.log(this.$i18n._vm._data.messages.lan)
            localStorage.setItem('languagecontent', res.data.content)
            this.$i18n.locale = 'lan'
            this.$i18n._vm._data.messages.lan = JSON.parse(localStorage.getItem('languagecontent'))
            this.currentLanguage = res.data.languageName
            this.$store.dispatch('dataStore/UPDATE_CURRENT_LANGUAGE', res.data.languageName)
            console.log(this.$store.getters['dataStore/GET_CURRENT_LANGUAGE'])

            this.$forceUpdate()
          }
        })
        .catch((error) => {
          console.warn(`Could not find any Language content : ${error}`)
        })
    },

    changLanguage (code) {
      if (code === undefined) {
        code = this.$store.getters['dataStore/GET_CURRENT_LANGUAGE']
      }
      let codeName = ''
      switch (code) {
        case 'en':
          codeName = 'English'
          break
        case 'ko':
          codeName = '한국어'
          break
        case 'de':
          codeName = 'Deutsche'
          break
        case 'fr':
          codeName = 'Francais'
          break
        case 'zh':
          codeName = '中文'
          break
        case 'es':
          codeName = 'Espanol'
          break
        case 'it':
          codeName = 'Italiano'
          break
        case 'por':
          codeName = 'Portugal'
          break
        case 'jp':
          codeName = '日本語'
          break
        default:
          codeName = 'English'
          code = 'en'
      }
      this.$i18n.locale = code
      this.currentLanguage = codeName
      this.$store.dispatch('dataStore/UPDATE_CURRENT_LANGUAGE', code)
      this.$forceUpdate()
    },
    processCloudLogout () {
      this.$store
        .dispatch('auth/logout')
        .then(res => {
          this.$store.dispatch('dataStore/CLEAN')
          cloudLogout()
        })
        .catch(error => {
          this.$store.dispatch('dataStore/CLEAN')
          this.loading = false
          this.message = error.message
        })
    },
    onLogout () {
      if (this.productionType === 'cloud') {
        this.processCloudLogout()
        return
      }
      const params = { account: this.user.account }
      const config = { params: params }
      this.$utils
        .callAxios(
          this.requests.logoutUser.method,
          this.requests.logoutUser.url,
          config
        )
        .finally(() => {
          this.$store
            .dispatch('auth/logout')
            .catch(error => {
              this.loading = false
              this.message = error.message
            })
            .finally(() => {
              this.RemoveSelectedStore()
              this.$store.dispatch(
                'dataStore/updateStaySignedIn',
                false
              )
              clearIntervals()
              this.$store.dispatch('dataStore/CLEAN')
              // Keycloak Based Logout
              if (configs.keycloakLogin === true) {
                const logoutUrl = configs.keycloakurl + '/realms/' + configs.keycloakrealm + '/protocol/openid-connect/logout?post_logout_redirect_uri=' + window.location.origin + process.env.BASE_URL + 'login'
                window.location.href = logoutUrl
              } else {
                this.$router.push('/login')
              }
            })
        })
    },
    RemoveSelectedStore () {
      const store = this.$store.state.dataStore.selectedStore
      if (store.company === undefined) store.company = ''
      store.country = ''
      store.region = ''
      store.city = ''
      store.code = ''
      store.name = ''
      store.description = ''
      return store
    },
    closeModal (state) {
      this.dialog_store = state
    },
    closeMainStoreModal (store, state) {
      this.dialog_store = state
      if (store && !commons.isNull(store.code)) this.searchStore = store
    },
    openSearchStore () {
      this.dialog_store = true
    },
    searchStoreFilter (item, queryText, itemText) {
      const itemStore = item.description.toLowerCase()
      const searchText = queryText.toLowerCase()
      return itemStore.indexOf(searchText) > -1
    },
    autoComplateSearch (val) {},
    toggleDropdown (event) {
      // / added due to only call the language api only
      // when language dropdown is opened
      if (
        event.target.name === 'language-dropdown' &&
        !event.target.classList.contains('isOpenend')
      ) {
        this.togetlanguagelist()
      }
      event.target.classList.toggle('isOpenend')
      // 09.27 버튼 외 화면 클릭시 메뉴 안보이게 처리
      document.addEventListener('click', function (e) {
        if (e.target !== event.target) {
          event.target.classList.remove('isOpenend')
        }
      })
    },
    getUserInfo () {
      this.user = this.$store.state.auth.user
      if (this.productionType === 'cloud') {
        this.displayUserId = this.$store.state.auth.user.tokenEmail
      } else {
        this.displayUserId = this.$store.state.auth.user.account
      }
    },
    setManagedStores () {
      const config = {
        params: {
          account: this.$store.state.auth.user.account,
          company: this.$store.state.auth.user.account.company
        }
      }
      this.$utils
        .callAxios(
          this.requests.getUser.method,
          this.requests.getUser.url,
          config
        )
        .then(res => {
          const data = res.data
          this.$store.dispatch('dataStore/UPDATE_MANAGED_STORE', data)
          EventBus.$emit('setMainPopupCompany')
        })
    },
    getMSPStatus () {
      const url = '/metro/msp-status'
      const vuex = JSON.parse(localStorage.getItem('vuex'))
      var config = {}
      if (configs.keycloakLogin === true) {
        config = {
          headers: { 'api-key': configs.ApiKey, Authorization: `Bearer ${vuex.auth.tokenAPI}` }
        }
      }
      axios.get(configs.ServerAddress + url, config)
        .then(response => {
          console.log(response)
          if (response.data.msp_enabled === false) {
            this.MSPStatus = true
          } else {
            this.MSPStatus = false
          }
          localStorage.setItem('MSPStatus', this.MSPStatus)
        }).catch(err => {
          console.log(err)
          this.MSPStatus = false
        })
    },
    gerreservedData () {
      const config = { params: {} }
      this.$utils
        .callAxios(
          this.requests.gerreservedData.method,
          this.requests.gerreservedData.url,
          config
        )
        .then(res => {
          const data = res.data
          console.log(data)
          localStorage.setItem('reservedItems', JSON.stringify(data))
        })
    },
    // toenableLCDtab () {
    //   const config = { params: {} }
    //   this.$utils
    //     .callAxios(
    //       this.requests.toenableLCDtab.method,
    //       this.requests.toenableLCDtab.url,
    //       config
    //     )
    //     .then(res => {
    //       const data = res.data
    //       console.log(data.lcdtab)
    //       const arr = [data]
    //       console.log(arr[0])

    //       localStorage.setItem('LcdTabEnable', data)
    //     })
    //     .catch(err => {
    //       console.log(err)
    //       console.log(err.response.status)
    //       localStorage.setItem('LcdTabEnable', 'false')
    //       this.$forceUpdate()
    //     })
    // },
    TogetTimezone (data) {
      if (data.code !== '' || data.code !== null) {
        const params = { store: data.code, isLabelAlive: configs.isLabelAlive }
        const config = { params }
        this.$utils
          .callAxios(
            this.requests.getStoreSummary.method,
            this.requests.getStoreSummary.url,
            config
          )
          .then(res => {
            this.$store.dispatch('dataStore/updateTimezone', res.data.zoneId)
          })
      }
    }
  },
  mounted () {
    // localStorage.setItem('LcdTabEnable', 'false')
    if (this.productionType === 'cloud') {
      this.MyAccountLink = '/User/MyAccountCloud'
    }
    if (this.$store.state.dataStore.staySignedIn) keepUserSignedIn()
    this.DashBoard_Release = configs.Release
    // this.changLanguage()
    this.searchStore = this.$store.getters['dataStore/GET_SELECTED_STORE']
    this.getUserInfo()
    this.togetlanguagelist()
    this.MenuLogoImage = this.$store.state.dataStore.MenuLogoImage
    if (CUSTOMER === 'METRO') {
      this.getMSPStatus()
      this.gerreservedData()
    }
    // this.toenableLCDtab()
  },
  beforeDestroy () {
    EventBus.$off('setSearchStore')
    EventBus.$off('openSelectedStores')
    EventBus.$off('enableSelectedStores')
    EventBus.$off('setManagedStores')
    EventBus.$off('messageAlert')
    EventBus.$off('loadingOn')
    EventBus.$off('loadingOff')
    commons.clearVuex()
  },
  destroyed () {
    clearIntervals()
  }
}
</script>
