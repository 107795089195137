var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "swInfo" },
    [
      _c(
        "v-dialog",
        {
          key: _vm.dialog_sw,
          attrs: { width: "1000" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "btn-swInfo",
                          attrs: { text: "" },
                          on: { click: _vm.handleSoftwareInfoClick }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_vm._v(" " + _vm._s(_vm.$t("Software Information")) + " ")]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.dialog_sw,
            callback: function($$v) {
              _vm.dialog_sw = $$v
            },
            expression: "dialog_sw"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "popup add_store_popup" },
            [
              _c("h3", { staticClass: "page-title-bar" }, [
                _c("i", { staticClass: "ico ico-info" }),
                _vm._v(_vm._s(_vm.$t("Software Information")) + " ")
              ]),
              _c("h3", { staticClass: "tit-swInfo" }, [
                _vm._v(_vm._s(_vm.$t("DASHBOARD REVISION")))
              ]),
              _c("v-text-field", {
                staticClass: "form-input mt-2",
                staticStyle: { width: "290px" },
                attrs: {
                  value: _vm.dashboardRevision,
                  outlined: "",
                  dense: "",
                  "hide-details": "",
                  solo: "",
                  readonly: ""
                }
              }),
              _c(
                "label",
                { staticStyle: { float: "right", "margin-top": "-25px" } },
                [
                  _c("span", { staticClass: "tit-swInfo" }, [
                    _vm._v("Version : ")
                  ]),
                  _vm._v(" v23.11 ")
                ]
              ),
              _c("article", [
                _c("table", { staticClass: "tbl-swInfo mt-10" }, [
                  _c("colgroup", [
                    _c("col", { staticStyle: { width: "12%" } }),
                    _c("col", { staticStyle: { width: "18%" } }),
                    _c("col", { staticStyle: { width: "16%" } }),
                    _c("col", { staticStyle: { width: "18%" } }),
                    _c("col", { staticStyle: { width: "10%" } }),
                    _c("col", { staticStyle: { width: "10%" } }),
                    _c("col")
                  ]),
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v(_vm._s(_vm.$t("TYPE")))]),
                      _c("th", [_vm._v(_vm._s(_vm.$t("CODE")))]),
                      _c("th", [_vm._v(_vm._s(_vm.$t("ADDRESS")))]),
                      _c("th", [_vm._v(_vm._s(_vm.$t("RELEASE VERSION")))]),
                      _c("th", [_vm._v(_vm._s(_vm.$t("REVISION")))]),
                      _c("th", [_vm._v(_vm._s(_vm.$t("STATUS")))]),
                      _c("th", [_vm._v(_vm._s(_vm.$t("LAST CONNECTED")))])
                    ])
                  ]),
                  _c(
                    "tbody",
                    [
                      _vm._l(_vm.softwareInformationListByType, function(
                        softwareInformationList
                      ) {
                        return _vm._l(softwareInformationList, function(
                          softwareInformation,
                          idx
                        ) {
                          return _c(
                            "tr",
                            {
                              key:
                                softwareInformationList.type +
                                softwareInformation.code +
                                softwareInformation.revision
                            },
                            [
                              idx === 0
                                ? _c(
                                    "td",
                                    {
                                      staticClass: "type",
                                      attrs: {
                                        rowspan: softwareInformationList.length
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(softwareInformation.nodeType) +
                                          " "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _c("td", [
                                _vm._v(_vm._s(softwareInformation.code))
                              ]),
                              _c("td", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      softwareInformation.ipAddress +
                                        ":" +
                                        softwareInformation.port
                                    ) +
                                    " "
                                )
                              ]),
                              _c("td", [
                                _vm._v(_vm._s(softwareInformation.version))
                              ]),
                              _c("td", [
                                _vm._v(_vm._s(softwareInformation.revision))
                              ]),
                              _c("td", [
                                _vm._v(_vm._s(softwareInformation.nodeStatus))
                              ]),
                              _c("td", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getLastConnectionDate(
                                        softwareInformation.lastConnectionDate
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            ]
                          )
                        })
                      })
                    ],
                    2
                  )
                ])
              ]),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: {
                        click: function($event) {
                          _vm.dialog_sw = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("Close")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }